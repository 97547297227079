export const palette = {
  white: '#ffffff',
  main: '#ffc758',
  main02: '#be7e00',
  main03: '#9f6a00',
  accent: '#70ba2c',
  accent02: '#41a233',
  gray1: '#f4f3f1',
  gray2: '#eae7e2',
  gray3: '#d3cdc5',
  gray4: '#beb5a7',
  gray5: '#a99c8a',
  gray6: '#766957',
  black: '#3d3529',
  alert: '#e1394d',
  background: '#fffaf1',
  marker: '#ffee81',
  merit: '#3969e1'
} as const

export type PaletteKeys = keyof typeof palette

export type MainAndAccent = 'main' | 'accent'
