import { useEffect } from 'react'
import { useRouter } from 'next/router'

export const usePageView = (): void => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        trackPageview: url,
        event: 'loadready'
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}
