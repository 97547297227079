import { FC } from 'react'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { palette } from '@lifedot/styles/palette'
import { WindowWidth, mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Link from 'next/link'

const styles = {
  link: css({
    display: 'flex',
    textDecoration: 'none',
    fontWeight: 'bold',
    [mq('min', WindowWidth.TABLET)]: {
      padding: '12px 24px',
      ':hover': {
        color: palette.main,
        background: palette.gray1
      }
    },
    [mq('max', WindowWidth.TABLET)]: {
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: 8,
      background: palette.white
    }
  }),
  nav: css({
    margin: 'auto',
    [mq('min', WindowWidth.TABLET)]: {
      maxWidth: 1080,
      ul: {
        display: 'flex'
      }
    },
    [mq('max', WindowWidth.TABLET)]: {
      padding: 8,
      background: palette.main,
      li: {
        ':not(:first-of-type)': {
          marginTop: 4
        }
      }
    }
  }),
  icon: css({
    display: 'none',
    [mq('max', WindowWidth.TABLET)]: {
      display: 'block'
    }
  })
}

export const GlobalNavi: FC = () => {
  return (
    <nav css={styles.nav}>
      <List>
        {links.map((link) => (
          <ListItem key={link.href}>
            <Link href={link.href} passHref>
              <a css={[typography.textM, styles.link]}>
                {link.title}
                <span css={styles.icon}>
                  <ChevronRightIcon fontSize="small" />
                </span>
              </a>
            </Link>
          </ListItem>
        ))}
      </List>
    </nav>
  )
}

const links = [
  { href: '/#a-search', title: 'お墓を探す' },
  { href: '/ohaka-price/', title: '一般墓' },
  { href: '/jumokusou/', title: '樹木葬' },
  { href: '/noukotsudou/', title: '納骨堂' },
  { href: '/eitaikuyou-haka/', title: '永代供養墓' },
  { href: '/ohaka-kaisou/', title: 'お墓の引越し' },
  { href: '/ohaka-jimai-price/', title: '墓じまい' }
]
