export const replacementImage = {
  path: 'https://s3.lifedot.jp/uploads/ending/path/62961/noImage_cemetery.png',
  alt: 'no image'
}
export type ReplacementImageType = typeof replacementImage

export const replacementImageForPost = {
  path: 'https://s3.lifedot.jp/uploads/ending/path/61382/img_noimage.png',
  alt: 'no image'
}
