import { useEffect, useMemo } from 'react'
import snakecaseKeys from 'snakecase-keys'
import {
  elementCategories,
  ElementCategory
} from '@lifedot/constants/elementCategories'

declare global {
  interface Window {
    dataLayer: Array<unknown>
  }
}

interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T
}

export const eventPush = ({
  category,
  action,
  label
}: {
  category?: string
  action?: string
  label?: string
}): void => {
  if (!category || !action || !label) return

  if (process.env.NEXT_PUBLIC_BUILD_ENV !== 'production')
    console.log(
      `EventTracking: category: ${category}, action: ${action}, label: ${label}`
    )

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'event_tracking',
    event_category: category,
    event_action: action,
    event_label: label
  })
}

export type EventProps =
  | {
      event: 'click_element' | 'view_contents'
      elementCategory?: ElementCategory
      elementLabel: string | number
    }
  | {
      event: 'purchase'
      type: string
      transactionId: string
      itemId?: Array<number>
    }
  | {
      event: 'purchase_null'
      type: string
    }

export const ga4EventPush = (props: EventProps): void => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(snakecaseKeys(props))
}

const labelRemove = (label: string, filterStrRegex: string) =>
  label.replace(new RegExp(filterStrRegex, 'g'), '')

const labelParser = (label: string, e: HTMLElementEvent<HTMLElement>) =>
  label
    .replace(/\{innerText}/g, () => e.target.innerText)
    .replace(/\{siblingsText}/g, () => e.target.parentElement?.innerText ?? '')

const recursiveSearch = <T extends HTMLElement = HTMLElement>(
  t: T | ParentNode | null,
  actionName: string
): T | null => {
  if (!t) return null
  if (
    'dataset' in t &&
    'eventTracking' in t.dataset &&
    t.dataset.eventAction === actionName
  )
    return t
  if (!('parentNode' in t) || !t.parentNode) return null
  return recursiveSearch<T>(t.parentNode, actionName)
}

const clicker =
  (passedCategory: string) => (e: HTMLElementEvent<HTMLElement>) => {
    if (e.type !== 'click') return
    const action = 'click'
    const target = recursiveSearch(e.target, action)
    if (!target) return

    const { eventCategory, eventElementCategory, eventElementLabel } =
      target.dataset

    const label = labelRemove(
      labelParser(target.dataset.eventLabel ?? '', e),
      target.dataset.eventLabelRemove ?? ''
    )
    const category = eventCategory ?? passedCategory

    const ga4EventProps = {
      eventElementLabel,
      eventElementCategory
    }

    if (isEnableGa4Push(ga4EventProps))
      ga4EventPush({
        event: 'click_element',
        elementCategory: ga4EventProps.eventElementCategory,
        elementLabel: labelParser(ga4EventProps.eventElementLabel, e)
      })
    eventPush({
      category,
      action,
      label
    })
  }

const isInputEvent = (
  e: HTMLElementEvent<HTMLElement>
): e is HTMLElementEvent<HTMLInputElement> => 'checked' in e.target

const changer =
  (passedCategory: string) => (e: HTMLElementEvent<HTMLElement>) => {
    if (e.type !== 'change') return

    const checkedTarget = recursiveSearch(e.target, 'checked')
    if (checkedTarget && isInputEvent(e) && e.target.checked) {
      eventPush({
        category: checkedTarget.dataset.eventCategory ?? passedCategory,
        action: 'checked',
        label: labelRemove(
          labelParser(checkedTarget.dataset.eventLabel ?? '', e),
          checkedTarget.dataset.eventLabelRemove ?? ''
        )
      })
      const { eventElementCategory, eventElementLabel } = checkedTarget.dataset
      const ga4EventProps = {
        eventElementLabel,
        eventElementCategory
      }
      if (isEnableGa4Push(ga4EventProps))
        ga4EventPush({
          event: 'click_element',
          elementCategory: ga4EventProps.eventElementCategory,
          elementLabel: labelParser(ga4EventProps.eventElementLabel, e)
        })
    }

    const changedTarget = recursiveSearch(e.target, 'change')
    if (changedTarget) {
      eventPush({
        category: changedTarget.dataset.eventCategory ?? passedCategory,
        action: 'change',
        label: labelRemove(
          labelParser(changedTarget.dataset.eventLabel ?? '', e),
          changedTarget.dataset.eventLabelRemove ?? ''
        )
      })
      const { eventElementCategory, eventElementLabel } = changedTarget.dataset
      const ga4EventProps = {
        eventElementLabel,
        eventElementCategory
      }
      if (isEnableGa4Push(ga4EventProps))
        ga4EventPush({
          event: 'click_element',
          elementCategory: ga4EventProps.eventElementCategory,
          elementLabel: labelParser(ga4EventProps.eventElementLabel, e)
        })
    }
  }

interface EventTrackingConfig {
  category: string
}

export interface EventTrackingDataAttribute {
  'data-event-element-category'?: ElementCategory
  'data-event-element-label'?: string | number
  'data-event-tracking': boolean
  'data-event-category'?: string
  'data-event-action': 'click' | 'checked' | 'change'
  'data-event-label': string
  'data-event-label-remove'?: string
}

export const useEventTracking = (config: EventTrackingConfig): void => {
  const handleClick = useMemo(() => clicker(config.category), [config.category])

  useEffect(() => {
    window.document.addEventListener('click', {
      handleEvent: handleClick
    })

    return () => {
      window.document.removeEventListener('click', {
        handleEvent: handleClick
      })
    }
  }, [handleClick])

  const handleChange = useMemo(
    () => changer(config.category),
    [config.category]
  )
  useEffect(() => {
    window.document.addEventListener('change', {
      handleEvent: handleChange
    })

    return () => {
      window.document.removeEventListener('change', {
        handleEvent: handleChange
      })
    }
  }, [handleChange])
}

type Ga4DataLayerProps = {
  eventElementLabel: string
  eventElementCategory: ElementCategory | undefined
}

// categoryが空の時はそのままpushし、存在するときはelementCategoriesに含まれるか確認
const isEnableGa4Push = (props: {
  eventElementLabel: string | undefined
  eventElementCategory: string | undefined
}): props is Ga4DataLayerProps => {
  const { eventElementLabel, eventElementCategory } = props
  if (!eventElementLabel) return false
  if (!eventElementCategory) return true
  return Object.values(elementCategories).includes(
    eventElementCategory as ElementCategory
  )
}
