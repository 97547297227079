import { cloneElement, FC, HTMLAttributes, isValidElement } from 'react'
import { EventTrackingDataAttribute } from '../use-event-tracking'

interface EventTrackerProps extends HTMLAttributes<HTMLDivElement> {
  action: EventTrackingDataAttribute['data-event-action']
  category?: EventTrackingDataAttribute['data-event-category']
  labelRemove?: EventTrackingDataAttribute['data-event-label-remove']
  clone?: boolean
  disabled?: boolean
  label: EventTrackingDataAttribute['data-event-label']
  elementCategory?: EventTrackingDataAttribute['data-event-element-category']
  elementLabel?: EventTrackingDataAttribute['data-event-element-label']
}

export const EventTracker: FC<EventTrackerProps> = ({ ...props }) => {
  const {
    elementCategory,
    elementLabel,
    disabled,
    label,
    action,
    category,
    children,
    labelRemove,
    clone,
    ...rest
  } = props

  if (clone && isValidElement<EventTrackingDataAttribute>(children))
    return cloneElement(children, {
      'data-event-tracking': !disabled,
      'data-event-category': category,
      'data-event-action': action,
      'data-event-label': label,
      'data-event-label-remove': labelRemove,
      'data-event-element-category': elementCategory,
      'data-event-element-label': elementLabel,
      ...rest
    })

  return (
    <div
      {...rest}
      data-event-tracking={!disabled}
      data-event-category={category}
      data-event-action={action}
      data-event-label={label}
      data-event-label-remove={labelRemove}
      data-event-element-category={elementCategory}
      data-event-element-label={elementLabel}
      children={children}
    />
  )
}
