import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import LaunchIcon from '@material-ui/icons/Launch'

const styles = {
  root: css({
    marginTop: 20,
    li: {
      display: 'inline-block',
      marginRight: 8,
      a: typography.textXS
    }
  }),
  icon: css({
    verticalAlign: 'sub'
  })
}

export const RelatedSite: FC = () => {
  return (
    <div css={styles.root}>
      <p>
        <b css={typography.textS}>エイチームグループ関連サイト</b>
      </p>
      <ul>
        {relatedSites.map((link) => (
          <li key={link.href}>
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              {link.title}
              {icon}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

const icon = <LaunchIcon style={{ fontSize: 14 }} css={styles.icon} />

interface Item {
  href: string
  bold?: boolean
  target?: boolean
  title: string
}

export const relatedSites: Item[] = [
  {
    href: 'https://a-cashing.com/',
    title: 'キャッシング比較・情報サイト ナビナビキャッシング'
  },
  {
    href: 'https://www.navinavi-hoken.com/',
    title: '【ナビナビ保険】保険比較・FPに無料相談できる総合保険サイト'
  },
  {
    href: 'https://www.a-tm.co.jp/top/',
    title: '暮らしとお金の情報メディア「イーデス」'
  },
  { href: 'https://www.navikuru.jp/', title: '車買取･査定ならナビクル' },
  { href: 'https://hana-yume.net/', title: '結婚式場探しはハナユメ' },
  {
    href: 'https://www.soldi.jp/',
    title: '格安SIMの比較・おすすめサイトソルディ'
  },
  { href: 'https://hikkoshizamurai.jp/', title: '引っ越し見積もりの引越し侍' },
  {
    href: 'https://moving.a-tm.co.jp/',
    title: '引越し業者を比較して一番安い見積もりがわかる引越し価格ガイド'
  },
  {
    href: 'https://www.pianocenter.jp/',
    title: 'ピアノ買取センター'
  },
  {
    href: 'https://career-picks.com/',
    title: '転職サービス比較サイト【CAREER PICKS】'
  },
  {
    href: 'https://www.jumokusou-tokyo.jp/',
    title: '樹木葬の専門メディア「悠々樹木葬 ～東京～」'
  },
  {
    href: 'https://www.noukotsudou-tokyo.jp/',
    title: '納骨堂の専門メディア「悠々納骨堂 ～東京～」'
  },
  {
    href: 'https://www.eitaikuyoubo-osaka.jp/',
    title: '永代供養墓の専門メディア「悠々永代供養墓 ～大阪～」'
  },
  {
    href: 'https://qiita.com/',
    title: 'エンジニアに関する知識を記録・共有するためのサービス「Qiita」'
  },
  {
    href: 'https://teams.qiita.com/',
    title: '社内向け情報共有サービス「Qiita Team」'
  },
  {
    href: 'https://business.qiita.com/',
    title: 'エンジニアにアプローチしたい法人向けサイト「Qiita for Business」'
  },
  {
    href: 'https://jobs.qiita.com/',
    title: 'エンジニアのキャリアを創るはじめての転職ガイド「Qiita Job Change」'
  }
]
