export const getDirector = (
  // TODO: 引数は使用していないため、削除予定
  id: number
): {
  name: string
  roma: string
  iconImagePath: string
  nameImagePath: string
  textLinkPath: string
} => {
  return {
    name: '奥谷',
    roma: 'okuya',
    iconImagePath:
      'https://s3.lifedot.jp/uploads/ending/path/60543/director_icon_okuya.png',
    nameImagePath:
      'https://s3.lifedot.jp/uploads/ending/path/61117/director_name_okuya.png',
    textLinkPath: '/okuya-kenta/'
  }
}
