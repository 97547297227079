import { FC, Children, isValidElement, cloneElement, ReactElement } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { ListItemProps } from './ListItem'

const styles = {
  root: css({
    li: {
      ':not(:first-of-type)': {
        borderTop: `1px solid ${palette.gray2}`
      }
    }
  }),
  horizontal: css({
    display: 'flex'
  })
}

const hasIconProps = (
  child: ReactElement
): child is ReactElement<Required<ListItemProps>> => 'icon' in child.props

export interface ListProps extends ListItemProps {
  number?: boolean
  border?: boolean
  orientation?: 'horizontal' | 'vertical'
}

export const List: FC<ListProps> = (props) => {
  const {
    children,
    number = false,
    border = false,
    orientation = 'vertical',
    icon
  } = props
  const newChildren = Children.map(children, (child) => {
    if (!isValidElement<ListItemProps>(child)) return child
    if (hasIconProps(child))
      return cloneElement(child, { icon: child.props.icon })
    return cloneElement(child, { icon })
  })
  return number ? (
    <ol
      css={[
        border && styles.root,
        orientation === 'horizontal' && styles.horizontal
      ]}
    >
      {newChildren}
    </ol>
  ) : (
    <ul
      css={[
        border && styles.root,
        orientation === 'horizontal' && styles.horizontal
      ]}
    >
      {newChildren}
    </ul>
  )
}
