import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const styles = {
  root: (dark: DividerProps['dark']) =>
    css({
      border: 'none',
      height: 1,
      margin: 0,
      backgroundColor: dark ? palette.gray3 : palette.gray2
    }),
  vertical: css({
    width: 1,
    height: 'auto'
  })
}

export interface DividerProps {
  orientation?: 'horizontal' | 'vertical'
  dark?: boolean
}

export const Divider: FC<DividerProps> = (props) => {
  const { orientation = 'horizontal', dark = false } = props
  return (
    <hr
      css={[styles.root(dark), orientation === 'vertical' && styles.vertical]}
    />
  )
}
