import { ReactNode } from 'react'
import { css } from '@emotion/react'
import LaunchIcon from '@material-ui/icons/Launch'

export type Sitemaps = Item[][]

interface Item {
  href: string
  bold?: boolean
  target?: boolean
  title: ReactNode
}

export const sitemaps: Sitemaps = [
  [
    { href: '/', bold: true, title: 'ライフドット トップ' },
    { href: '/#a-search', title: '霊園検索' },
    { href: '/ohaka/area_partner_requests/new/', title: 'お墓の資料請求' },
    { href: '/ohaka/dialogue/step1/', title: 'ぴったりお墓診断' }
  ],
  [
    { href: '/ohaka/', bold: true, title: 'お墓について' },
    { href: '/bochi-kounyu/', title: 'はじめてのお墓購入' },
    { href: '/ohaka-price/', title: 'お墓の価格' },
    { href: '/ohaka-erabikata/', title: 'お墓の選び方' },
    { href: '/ohaka-kaisou/', title: 'お墓の引越し（改葬）' },
    { href: '/ohaka-jimai-price/', title: '墓じまい' }
  ],
  [
    { href: '/ohaka-shurui/', bold: true, title: 'お墓の種類' },
    { href: '/boseki/', title: '一般墓' },
    { href: '/eitaikuyou-haka/', title: '永代供養墓' },
    { href: '/jumokusou/', title: '樹木葬' },
    { href: '/noukotsudou/', title: '納骨堂' }
  ],
  [
    { href: '/ohaka-tateru/', bold: true, title: 'お墓を建てる' },
    { href: '/boseki-price/', title: '墓石の価格' },
    { href: '/ohaka-kanrihi/', title: 'お墓の管理費' },
    { href: '/ohaka-reform/', title: 'お墓のリフォーム' }
  ],
  [
    { href: '/ohaka-visiting/', bold: true, title: 'お墓参り' },
    { href: '/ohaka-manner/', title: 'お墓参りのマナー' },
    { href: '/ohakamairi-osonaemono/', title: 'お墓参りのお供え' },
    { href: '/ohaka-hukusou/', title: 'お墓参りの服装' },
    { href: '/ohakamairi-frequency/', title: 'お墓参りの時期' }
  ],
  [
    { href: '/sougi/', bold: true, title: '葬儀について' },
    { href: '/butsudan/', bold: true, title: '仏壇・仏具について' },
    { href: '/souzoku/', bold: true, title: '相続について' },
    { href: '/kaigo/', bold: true, title: '生前準備・終活について' }
  ]
]

const styles = {
  icon: css({
    verticalAlign: 'sub'
  })
}

export type ServiceInfos = Item[]

export const serviceInfos: ServiceInfos = [
  { href: '/company_info/', title: '運営者情報' },
  { href: '/agreement/', title: '利用規約' },
  {
    href: 'https://life-design.a-tm.co.jp/privacy/',
    target: true,
    title: (
      <>
        プライバシーポリシー
        <LaunchIcon style={{ fontSize: 14 }} css={styles.icon} />
      </>
    )
  },
  { href: '/review/', title: '口コミについて' },
  { href: '/contacts/new/', title: 'お問い合わせ' },
  { href: '/supervision-person/', title: '監修者情報' }
]
