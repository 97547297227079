import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import Image from 'next/image'

const styles = {
  root: css({
    backgroundColor: palette.white,
    margin: '0 calc(-50vw + 50%)'
  }),
  description: css({
    display: 'grid',
    gridTemplateColumns: '110px 1fr',
    alignItems: 'center',
    gap: 24,
    maxWidth: 1080,
    margin: 'auto',
    padding: 20,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 4,
      padding: '16px 12px 12px'
    }
  }),
  logo: css({
    [mq('sp')]: {
      width: 74
    }
  })
}

type SiteDescriptionProps = { description: string }

export const SiteDescription: FC<SiteDescriptionProps> = ({ description }) => {
  return (
    <div css={styles.root}>
      <div css={styles.description}>
        <span css={styles.logo}>
          <Image
            src="https://s3.lifedot.jp/uploads/ending/path/52461/logo.png"
            width={110}
            height={35}
            alt="お墓・霊園探しならライフドット"
          />
        </span>
        <p css={typography.textXS}>{description}</p>
      </div>
    </div>
  )
}
