export const elementCategories = {
  selectArea: 'select_area',
  selectConditions: 'select_conditions',
  selectSectiontype: 'select_sectiontype',
  selectTab: 'select_tab',
  selectSort: 'select_sort',
  cemeteryCard: 'cemetery_card',
  stockList: 'stock_list',
  cta: 'cta',
  breadcrumb: 'breadcrumb',
  faq: 'faq',
  resultCemeteryCard: 'result_cemetery_card',
  resultCityname: 'result_cityname',
  reienName: 'reien_name',
  anchor: 'anchor',
  similarCemeteryCard: 'similar_cemetery_card',
  cemeteryIntroduction: 'cemetery_introduction'
} as const

export type ElementCategory =
  (typeof elementCategories)[keyof typeof elementCategories]
