export const priceFormatter = (
  num: number,
  option?: { split?: boolean; decimalPoints?: number }
): string | string[] => {
  if (!Number.isFinite(num)) return option?.split ? ['', ''] : ''

  const decimal =
    option?.decimalPoints !== undefined ? 10 ** option.decimalPoints : 10

  const formatted =
    num < 10000
      ? String(num)
      : num >= 10000 && num < 100000000
      ? `${Math.round((num / 10000) * decimal) / decimal}万`
      : `${Math.round((num / 100000000) * decimal) / decimal}億`
  if (!option?.split) return formatted
  return num < 10000 ? [formatted, ''] : formatted.split(/(?=万|億)/)
}
