type Arguments = ['pc' | 'sp'] | ['max' | 'min', number]

export const mq = (...arg: Arguments): string => {
  const [device, bp = 0] = arg
  if (device === 'pc') return `@media (min-width: ${WindowWidth.SP + 1}px)`
  if (device === 'sp') return `@media (max-width: ${WindowWidth.SP}px)`
  if (device === 'max') return `@media (max-width: ${bp}px)`
  return `@media (min-width: ${bp}px)`
}

export const WindowWidth = { TABLET: 1024, SP: 640 } as const
