// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AttributeCode {
  export const FACILITY = 'facility'
  export const FEATURE = 'feature'
  export const SECT = 'sect'
  export const SECTION_TYPE = 'section_type'
  export const CEMETERY_TYPE = 'cemetery_type'
  export const PRICE_MAX = 'price_max'
  export const PRICE_MIN = 'price_min'
  export const SECTION_CAPACITY = 'section_capacity'
}
export type AttributeCodeType =
  (typeof AttributeCode)[keyof typeof AttributeCode]

export type Condition<T extends AttributeCodeType = AttributeCodeType> = {
  readonly code: string
  readonly attributeCode: T
  readonly name: string
  readonly noIndex?: boolean
}

type Conditions<T extends AttributeCodeType = AttributeCodeType> =
  ReadonlyArray<Condition<T>>

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FacilityCode {
  export const BARRIER_FREE = 'barrier_free'
  export const PARKING = 'parking'
  export const WITHOUT_ANNUAL_MANAGEMENT_FEE = 'without_annual_management_fee'
  export const MANAGER_RESIDENT = 'manager_resident'
}

export const facilities: Conditions<typeof AttributeCode.FACILITY> = [
  {
    code: FacilityCode.BARRIER_FREE,
    attributeCode: AttributeCode.FACILITY,
    name: 'バリアフリー対応',
    noIndex: false
  },
  {
    code: FacilityCode.PARKING,
    attributeCode: AttributeCode.FACILITY,
    name: '駐車場あり',
    noIndex: true
  },
  {
    code: FacilityCode.WITHOUT_ANNUAL_MANAGEMENT_FEE,
    attributeCode: AttributeCode.FACILITY,
    name: '年間管理費なし',
    noIndex: false
  },
  {
    code: FacilityCode.MANAGER_RESIDENT,
    attributeCode: AttributeCode.FACILITY,
    name: '管理人常駐',
    noIndex: false
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FeatureCode {
  export const PET = 'pet'
  export const NEAR_A_STATION = 'near_a_station'
  export const SUCCESSOR_EXISTENCE = 'successor_existence'
  export const DANKA_SYSTEM = 'danka_system'
  export const NEW_OPEN = 'new_open'
  export const VIEW_STATE = 'view_state'
  export const LIFETIME_REQUEST = 'lifetime_request'
}

export const features: Conditions<typeof AttributeCode.FEATURE> = [
  {
    code: FeatureCode.NEAR_A_STATION,
    attributeCode: AttributeCode.FEATURE,
    name: '駅から徒歩5分圏内',
    noIndex: true
  },
  {
    code: FeatureCode.SUCCESSOR_EXISTENCE,
    attributeCode: AttributeCode.FEATURE,
    name: '永代供養プランあり',
    noIndex: false
  },
  {
    code: FeatureCode.DANKA_SYSTEM,
    attributeCode: AttributeCode.FEATURE,
    name: '檀家になる必要なし',
    noIndex: true
  },
  {
    code: FeatureCode.LIFETIME_REQUEST,
    attributeCode: AttributeCode.FEATURE,
    name: '生前申込できる',
    noIndex: true
  },
  {
    code: FeatureCode.PET,
    attributeCode: AttributeCode.FEATURE,
    name: 'ペット供養できる',
    noIndex: false
  },
  {
    code: FeatureCode.NEW_OPEN,
    attributeCode: AttributeCode.FEATURE,
    name: '新規開園',
    noIndex: true
  },
  {
    code: FeatureCode.VIEW_STATE,
    attributeCode: AttributeCode.FEATURE,
    name: '眺めがいい',
    noIndex: true
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SectCode {
  export const NONE = 'none'
  export const SHINGON = 'shingon'
  export const JODO = 'jodo'
  export const JODOSHIN = 'jodoshin'
  export const NICHIREN = 'nichiren'
  export const TENDAI = 'tendai'
  export const SOTO = 'soto'
  export const RINZAI = 'rinzai'
  export const SHINTO = 'shinto'
  export const SOKA = 'soka'
  export const SHINSHU_TAKADA = 'shinshu_takada'
  export const SHINSHU_OTANI = 'shinshu_otani'
  export const HOKKE = 'hokke'
  export const OBAKU = 'obaku'
  export const NATIVE_BUDDHISM = 'native_buddhism'
  export const OTHER = 'other'
  export const YUZU_NENBUTSU = 'yuzu_nenbutsu'
}

export const sects: Conditions<typeof AttributeCode.SECT> = [
  {
    code: SectCode.NONE,
    attributeCode: AttributeCode.SECT,
    name: '宗教不問',
    noIndex: false
  },
  {
    code: SectCode.NATIVE_BUDDHISM,
    attributeCode: AttributeCode.SECT,
    name: '在来仏教',
    noIndex: false
  },
  {
    code: SectCode.JODOSHIN,
    attributeCode: AttributeCode.SECT,
    name: '浄土真宗',
    noIndex: false
  },
  {
    code: SectCode.SOTO,
    attributeCode: AttributeCode.SECT,
    name: '曹洞宗',
    noIndex: false
  },
  {
    code: SectCode.SHINGON,
    attributeCode: AttributeCode.SECT,
    name: '真言宗',
    noIndex: false
  },
  {
    code: SectCode.NICHIREN,
    attributeCode: AttributeCode.SECT,
    name: '日蓮宗',
    noIndex: false
  },
  {
    code: SectCode.JODO,
    attributeCode: AttributeCode.SECT,
    name: '浄土宗',
    noIndex: false
  },
  {
    code: SectCode.RINZAI,
    attributeCode: AttributeCode.SECT,
    name: '臨済宗',
    noIndex: false
  },

  {
    code: SectCode.TENDAI,
    attributeCode: AttributeCode.SECT,
    name: '天台宗',
    noIndex: false
  },
  {
    code: SectCode.SHINSHU_OTANI,
    attributeCode: AttributeCode.SECT,
    name: '真宗大谷派',
    noIndex: false
  },
  {
    code: SectCode.SHINSHU_TAKADA,
    attributeCode: AttributeCode.SECT,
    name: '真宗高田派',
    noIndex: false
  },
  {
    code: SectCode.OBAKU,
    attributeCode: AttributeCode.SECT,
    name: '黄檗宗',
    noIndex: false
  },
  {
    code: SectCode.HOKKE,
    attributeCode: AttributeCode.SECT,
    name: '法華宗',
    noIndex: false
  },
  {
    code: SectCode.YUZU_NENBUTSU,
    attributeCode: AttributeCode.SECT,
    name: '融通念仏宗',
    noIndex: false
  },
  {
    code: SectCode.SHINTO,
    attributeCode: AttributeCode.SECT,
    name: '神道',
    noIndex: false
  },
  {
    code: SectCode.SOKA,
    attributeCode: AttributeCode.SECT,
    name: '創価学会',
    noIndex: false
  },
  {
    code: SectCode.OTHER,
    attributeCode: AttributeCode.SECT,
    name: 'その他宗派',
    noIndex: true
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SectionTypeCode {
  export const EITAI_KUYO = 'eitai_kuyo'
  export const JUMOKU = 'jumoku'
  export const NOKOTSU = 'nokotsu'
  export const NORMAL = 'normal'
}

export const sectionTypes: Conditions<typeof AttributeCode.SECTION_TYPE> = [
  {
    code: SectionTypeCode.NORMAL,
    attributeCode: AttributeCode.SECTION_TYPE,
    name: '一般墓'
  },
  {
    code: SectionTypeCode.JUMOKU,
    attributeCode: AttributeCode.SECTION_TYPE,
    name: '樹木葬'
  },
  {
    code: SectionTypeCode.NOKOTSU,
    attributeCode: AttributeCode.SECTION_TYPE,
    name: '納骨堂'
  },
  {
    code: SectionTypeCode.EITAI_KUYO,
    attributeCode: AttributeCode.SECTION_TYPE,
    name: '永代供養墓'
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CemeteryTypeCode {
  export const KOEI = 'koei'
  export const MINEI = 'minei'
  export const TEMPLE = 'temple'
}

export const cemeteryTypes: Conditions<typeof AttributeCode.CEMETERY_TYPE> = [
  {
    code: CemeteryTypeCode.KOEI,
    attributeCode: AttributeCode.CEMETERY_TYPE,
    name: '公営霊園'
  },
  {
    code: CemeteryTypeCode.MINEI,
    attributeCode: AttributeCode.CEMETERY_TYPE,
    name: '民営霊園'
  },
  {
    code: CemeteryTypeCode.TEMPLE,
    attributeCode: AttributeCode.CEMETERY_TYPE,
    name: '寺院墓地'
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PriceMaxCode {
  export const PRICE_MAX_1 = 'price_max_1'
  export const PRICE_MAX_2 = 'price_max_2'
  export const PRICE_MAX_3 = 'price_max_3'
  export const PRICE_MAX_4 = 'price_max_4'
}

export const priceMaxes: Conditions<typeof AttributeCode.PRICE_MAX> = [
  {
    code: PriceMaxCode.PRICE_MAX_1,
    attributeCode: AttributeCode.PRICE_MAX,
    name: '～30万円',
    noIndex: true
  },
  {
    code: PriceMaxCode.PRICE_MAX_2,
    attributeCode: AttributeCode.PRICE_MAX,
    name: '～50万円',
    noIndex: true
  },
  {
    code: PriceMaxCode.PRICE_MAX_3,
    attributeCode: AttributeCode.PRICE_MAX,
    name: '～100万円',
    noIndex: true
  },
  {
    code: PriceMaxCode.PRICE_MAX_4,
    attributeCode: AttributeCode.PRICE_MAX,
    name: '～150万円',
    noIndex: true
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PriceMinCode {
  export const PRICE_MIN_1 = 'price_min_1'
  export const PRICE_MIN_2 = 'price_min_2'
  export const PRICE_MIN_3 = 'price_min_3'
  export const PRICE_MIN_4 = 'price_min_4'
}

export const priceMins: Conditions<typeof AttributeCode.PRICE_MIN> = [
  {
    code: PriceMinCode.PRICE_MIN_1,
    attributeCode: AttributeCode.PRICE_MIN,
    name: '30万円～',
    noIndex: true
  },
  {
    code: PriceMinCode.PRICE_MIN_2,
    attributeCode: AttributeCode.PRICE_MIN,
    name: '50万円～',
    noIndex: true
  },
  {
    code: PriceMinCode.PRICE_MIN_3,
    attributeCode: AttributeCode.PRICE_MIN,
    name: '100万円～',
    noIndex: true
  },
  {
    code: PriceMinCode.PRICE_MIN_4,
    attributeCode: AttributeCode.PRICE_MIN,
    name: '150万円～',
    noIndex: true
  }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SectionCode {
  export const HAS_SINGLE_SECTION = 'has_single_section'
  export const HAS_COUPLE_SECTION = 'has_couple_section'
  export const HAS_FAMILY_SECTION = 'has_family_section'
}

export const sectionCapacities: Conditions<
  typeof AttributeCode.SECTION_CAPACITY
> = [
  {
    code: SectionCode.HAS_SINGLE_SECTION,
    attributeCode: AttributeCode.SECTION_CAPACITY,
    name: '1人用区画あり',
    noIndex: false
  },
  {
    code: SectionCode.HAS_COUPLE_SECTION,
    attributeCode: AttributeCode.SECTION_CAPACITY,
    name: '2人用区画あり',
    noIndex: false
  },
  {
    code: SectionCode.HAS_FAMILY_SECTION,
    attributeCode: AttributeCode.SECTION_CAPACITY,
    name: '3人以上(家族)用区画あり',
    noIndex: false
  }
]

export const allSearchChoices = [
  ...facilities,
  ...features,
  ...sects,
  ...sectionTypes,
  ...cemeteryTypes,
  ...priceMaxes,
  ...priceMins,
  ...sectionCapacities
] as const

export const allSearchChoiceCode = allSearchChoices.map(({ code }) => code)

export const indexSearchChoices = allSearchChoices.filter(
  ({ noIndex }) => !noIndex
)
