import {
  RelayNetworkLayer,
  cacheMiddleware,
  urlMiddleware
} from 'react-relay-network-modern'
import RelaySSR from 'react-relay-network-modern-ssr/lib/client'
import { Environment, RecordSource, Store } from 'relay-runtime'
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment'
import { SSRCache } from 'react-relay-network-modern-ssr/lib/server'
import equal from 'fast-deep-equal'

const source = new RecordSource()
const store = new Store(source)

let storeEnvironment: RelayModernEnvironment | null = null
let storeData: SSRCache | null | undefined = null

export const createEnvironment = (
  relayData: SSRCache | null | undefined
): RelayModernEnvironment => {
  if (storeEnvironment && eqWithStoreData(relayData)) return storeEnvironment
  storeData = relayData

  storeEnvironment = new Environment({
    store,
    network: new RelayNetworkLayer([
      cacheMiddleware({
        size: 100,
        ttl: 60 * 1000
      }),
      new RelaySSR(relayData ?? []).getMiddleware({
        lookup: false
      }),
      urlMiddleware({
        url: process.env.NEXT_PUBLIC_RELAY_ENDPOINT ?? '',
        headers: {
          'X-API-KEY': process.env.NEXT_PUBLIC_RELAY_API_KEY ?? ''
        }
      })
    ])
  })

  return storeEnvironment
}

export const initEnvironment = (): void => {
  // no-op
}

const eqWithStoreData = (relayData: SSRCache | null | undefined) => {
  if (!storeData || !relayData) return false
  return equal(
    storeData.map(([cacheKey]) => cacheKey).sort(),
    relayData.map(([cacheKey]) => cacheKey).sort()
  )
}
