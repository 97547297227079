import type { FC } from 'react'
import { typography } from '@lifedot/styles/typography'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useIsPeriod } from '@lifedot/hooks/use-is-period'
import {
  CAMPAIGN_END_ON,
  CAMPAIGN_START_ON
} from '@lifedot/constants/campaignPeriod'

dayjs.extend(isBetween)

export const CampaignNote: FC = () => {
  const isCampaignPeriod = useIsPeriod(CAMPAIGN_START_ON, CAMPAIGN_END_ON)
  if (!isCampaignPeriod) return null
  return (
    <dl css={typography.textXS}>
      <dt>■キャンペーンについて</dt>
      <dd>
        株式会社エイチームライフデザイン主催キャンペーンです。Amazon、Amazon.co.jpおよびそれらのロゴはAmazon.com,Inc.またはその関連会社の商標です。
      </dd>
    </dl>
  )
}
