import {
  replacementImage,
  ReplacementImageType
} from '@lifedot/constants/images'

export const cemeteryMainImage = (
  image: { path?: string | null; alt?: string | null } | null
): ReplacementImageType => {
  if (!image) return replacementImage
  return {
    path: image.path ?? replacementImage.path,
    alt: image.alt ?? replacementImage.alt
  }
}
