import { FC, LiHTMLAttributes, ReactElement } from 'react'
import { css } from '@emotion/react'

const styles = {
  root: css({
    display: 'flex'
  }),
  icon: css({
    paddingRight: 4
  })
}

export interface ListItemProps extends LiHTMLAttributes<HTMLLIElement> {
  icon?: ReactElement
}

export const ListItem: FC<ListItemProps> = (props) => {
  const { icon, ...rest } = props
  return (
    <li css={styles.root} {...rest}>
      {icon && <div css={styles.icon}>{icon}</div>}
      {props.children}
    </li>
  )
}
