import { priceFormatter } from './priceFormatter'
import { SectionTypeCode } from '@lifedot/constants/searchChoices'
import { priceRound } from '.'

export interface PriceBase {
  category_code: string
  price1: number | null
  price1_unit: string | null
  price2: number | null
  price2_unit: string | null
  purchase_price: number | null
  purchase_price_unit: string | null
  calculated_purchase_price: number | null
  purchase_price_option: string
  price3: number | null
  simulated_price3: number | null
  price4: number | null
  price4_name: string | null
  lot_area: number | null
}

export const deducePurchasePrice = ({
  purchase_price,
  calculated_purchase_price,
  price1,
  category_code,
  lot_area,
  simulated_price3,
  price3
}: Partial<PriceBase>): number => {
  if (
    price1 &&
    simulated_price3 &&
    isReference({
      category_code,
      purchase_price,
      price1,
      lot_area,
      price3,
      simulated_price3
    })
  )
    // 石材店へのペナルティ防止の為、表示金額が霊園の最低価格を下回らないように参考墓石工事代に10%上乗せした価格を足して表示する
    return priceRound(price1 + simulated_price3 * 1.1)
  return purchase_price ?? calculated_purchase_price ?? price1 ?? NaN
}

export const isReference = ({
  category_code,
  purchase_price,
  price1,
  lot_area,
  price3,
  simulated_price3
}: Partial<PriceBase>): boolean => {
  if (
    category_code === SectionTypeCode.NORMAL &&
    !purchase_price &&
    price1 &&
    lot_area &&
    !price3 &&
    simulated_price3
  )
    return true
  return false
}

export const unitString = (price: PriceBase): string => {
  if (!(deducePurchasePrice(price) > 0)) return ''

  const {
    purchase_price_unit,
    price1_unit,
    purchase_price,
    calculated_purchase_price
  } = price
  return (
    (purchase_price || calculated_purchase_price
      ? purchase_price_unit
      : price1_unit) || '円〜'
  )
}

export const supplementString = (price: Partial<PriceBase>): string => {
  if (!(deducePurchasePrice(price) > 0) || price.purchase_price) return ''

  const {
    category_code,
    purchase_price_option,
    price4,
    price4_name,
    purchase_price,
    price1,
    price3,
    simulated_price3,
    calculated_purchase_price
  } = price

  if (
    category_code === SectionTypeCode.NORMAL &&
    price1 &&
    !calculated_purchase_price &&
    !purchase_price &&
    !price3 &&
    !simulated_price3
  )
    return '+墓石代'

  if (purchase_price_option === 'required_price4' && !price4)
    return ` +${price4_name ?? 'その他費用'}`

  return ''
}

const managementFeeString = (price: PriceBase): string =>
  price.price2 !== null && Number(price.price2) >= 0
    ? `${Number(price.price2).toLocaleString()}${price.price2_unit || '円〜'}`
    : ''

export interface DisplayablePurchasePrice {
  priceNumber: string
  priceUnit: string
  priceSupplement: string
  managementFee: string
}

export const displayablePurchasePrices = (
  price: PriceBase | undefined | null,
  unknownSuffix = 'お問い合わせください',
  unknownFee = '未掲載'
): DisplayablePurchasePrice => {
  if (!price)
    return {
      priceNumber: '',
      priceUnit: '',
      priceSupplement: unknownSuffix,
      managementFee: unknownFee
    }

  const [priceNumber, suffix] = priceFormatter(deducePurchasePrice(price), {
    split: true
  })
  const priceUnit = priceNumber ? `${suffix}${unitString(price)}` : ''
  const priceSupplement = priceNumber ? supplementString(price) : unknownSuffix
  const managementFee = managementFeeString(price) || unknownFee

  return {
    priceNumber: priceNumber ?? '',
    priceUnit,
    priceSupplement,
    managementFee
  }
}

export const bestDisplayablePurchasePrices = (
  prices: Record<string, PriceBase | null>
): DisplayablePurchasePrice | undefined => {
  const price = Object.values(prices).reduce<
    undefined | DisplayablePurchasePrice
  >((res, price) => {
    if (!price) return res
    const purchasePrice = displayablePurchasePrices(price)
    if (
      res?.priceNumber &&
      (Number(res.priceNumber) || Infinity) <
        (Number(purchasePrice.priceNumber) || Infinity)
    )
      return res
    return purchasePrice
  }, undefined)

  return price
}
