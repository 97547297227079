import { FeatureCode, FacilityCode } from '@lifedot/constants/searchChoices'

const features = [
  FeatureCode.SUCCESSOR_EXISTENCE,
  FeatureCode.PET,
  FacilityCode.BARRIER_FREE,
  FeatureCode.DANKA_SYSTEM
]

export const makeLabels = <T extends { code: string }>(choices: T[]): T[] =>
  features.reduce<T[]>((current, item) => {
    const feature = choices.find((v) => v.code === item)
    if (feature === undefined) return current
    return [...current, feature]
  }, [])
