import { css } from '@emotion/react'

export const typography = {
  textXL: css({
    fontSize: 20,
    lineHeight: 1.6,
    '@media (max-width: 767px)': {
      fontSize: 18
    }
  }),
  textL: css({
    fontSize: 18,
    lineHeight: 1.6,
    '@media (max-width: 767px)': {
      fontSize: 16
    }
  }),
  textM: css({
    fontSize: 16,
    lineHeight: 1.6,
    '@media (max-width: 767px)': {
      fontSize: 14
    }
  }),
  textS: css({
    fontSize: 14,
    lineHeight: 1.6,
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  }),
  textXS: css({
    fontSize: 12,
    lineHeight: 1.6,
    '@media (max-width: 767px)': {
      fontSize: 10
    }
  }),
  headingXL: css({
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 26
    }
  }),
  headingL: css({
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 24
    }
  }),
  headingM: css({
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 22
    }
  }),
  headingS: css({
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 20
    }
  }),
  headingXS: css({
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: 1.4,
    '@media (max-width: 767px)': {
      fontSize: 18
    }
  })
} as const
