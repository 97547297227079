import { FC } from 'react'
import { DefaultSeo as NextDefaultSeo } from 'next-seo'
import Head from 'next/head'

export const DefaultSeo: FC = () => {
  return (
    <>
      <NextDefaultSeo
        twitter={{ cardType: 'summary_large_image', site: '@lifedot_ending' }}
        openGraph={{
          type: 'article',
          site_name: 'お墓・霊園探しならライフドット',
          locale: 'ja_JP'
        }}
      />
      <Head>
        <meta
          name="p:domain_verify"
          content="f430a83de8e83eb6ae644553c73d17f6" // Pinterest
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Head>
    </>
  )
}
