import {
  ButtonHTMLAttributes,
  FC,
  ReactNode,
  useCallback,
  useState
} from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { WindowWidth, mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import { transition } from '@lifedot/styles/transition'

const styles = {
  root: css({
    width: '100%',
    background: palette.white,
    borderTop: `4px solid ${palette.main}`
  }),
  headerWrap: css({
    borderBottom: `1px solid ${palette.gray2}`
  }),
  header: css({
    maxWidth: 1080,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px 8px',
    margin: 'auto',
    alignItems: 'center',
    [mq('sp')]: {
      minHeight: 48
    }
  }),
  titleBox: css({
    display: 'flex',
    alignItems: 'center'
  }),
  title: css({
    marginLeft: 12
  }),
  tools: css({
    display: 'flex',
    alignItems: 'center'
  }),
  menu: css({
    [mq('min', WindowWidth.TABLET)]: {
      maxHeight: 500,
      borderBottom: `1px solid ${palette.gray2}`
    },
    [mq('max', WindowWidth.TABLET)]: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: transition('easeInOut')
    }
  }),
  isOpen: css({
    [mq('max', WindowWidth.TABLET)]: {
      maxHeight: 500
    }
  }),
  displayButton: css({
    display: 'none',
    [mq('max', WindowWidth.TABLET)]: {
      display: 'block'
    }
  })
}

export interface HeaderProps {
  logo: ReactNode
  menu?: ReactNode
  title?: string
  tools?: ReactNode
}

export const Header: FC<HeaderProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = useCallback(() => setIsOpen((prev) => !prev), [])
  return (
    <header css={styles.root} role="navigation">
      <div css={styles.headerWrap}>
        <div css={styles.header}>
          <div css={styles.titleBox}>
            {props.logo}
            {props.title && (
              <p css={[typography.textXS, styles.title]}>{props.title}</p>
            )}
          </div>
          <div css={styles.tools}>
            {props.tools}
            {props.menu && (
              <div css={styles.displayButton}>
                <MenuButton onClick={handleClick} />
              </div>
            )}
          </div>
        </div>
      </div>
      {props.menu && (
        <div css={[styles.menu, isOpen && styles.isOpen]}>{props.menu}</div>
      )}
    </header>
  )
}

const buttonStyles = {
  menu: css({
    display: 'block',
    width: 80,
    padding: '0 0 0 12px',
    backgroundColor: palette.white,
    borderLeft: `1px solid ${palette.gray2}`
  })
}

const MenuButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button css={[typography.textM, buttonStyles.menu]} {...props}>
    メニュー
  </button>
)
