import { ReactNode, memo, FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { Footer } from '@lifedot/components/Footer'
import Link from 'next/link'
import Image from 'next/image'
import { Header } from '@lifedot/components/Header'
import { GlobalNavi } from './GlobalNavi'
import { RelatedSite } from '@lifedot/components/Footer/RelatedSites'
import { SiteDescription } from '@lifedot/components/SiteDescription'

const styles = {
  root: css({
    background: palette.gray1
  }),
  logo: css({
    width: 132,
    display: 'flex',
    alignItems: 'center',
    [mq('sp')]: {
      width: 88
    }
  }),
  body: css({
    maxWidth: 1080,
    margin: 'auto',
    paddingBottom: 60
  })
}

interface BaseLayoutProps {
  children: ReactNode
  top?: boolean
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children, top = false }) => {
  return (
    <div css={styles.root}>
      <MemorizedHeader top={top} />
      <div css={styles.body}>{children}</div>
      {top && (
        <SiteDescription description="お墓探しのlife.（ライフドット）は、全国の霊園・墓地の中からおすすめのお墓や、ご自宅近くの樹木葬・納骨堂・永代供養墓・一般墓（墓石を建てるお墓）をご提案します。地域別の墓地一覧、費用相場、人気ランキングなど、お墓選びに役立つ情報が満載。お墓の評判・口コミや、詳細な交通アクセス・地図、料金・値段もご覧いただけます。民営霊園・公営霊園（市営・都立・都営）・有名寺院、宗教・宗派、ペット供養など、さまざまな特徴から比較してお墓を探せます。お墓の見学予約・資料請求の申込みのほか、墓石購入、お墓の移設、墓じまい後の遺骨の移動先・移す方法についても気軽にご相談ください。" />
      )}
      <MemorizedFooter top={top} />
    </div>
  )
}

const BeforeMemorizedFooter: FC<{ top: boolean }> = ({ top }) => (
  <Footer>{top && <RelatedSite />}</Footer>
)

const MemorizedFooter = memo(BeforeMemorizedFooter)

const BeforeMemorizedHeader: FC<{ top: boolean }> = ({ top }) => (
  <Header
    logo={
      top ? (
        <h1 itemProp="headline" css={styles.logo}>
          <Image
            src="https://s3.lifedot.jp/uploads/ending/path/52461/logo.png"
            width={132}
            height={42}
            alt="お墓・霊園探しならライフドット"
            priority
          />
        </h1>
      ) : (
        <Link href="/" prefetch={false} passHref>
          <a css={styles.logo} itemProp="headline">
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/52461/logo.png"
              width={132}
              height={42}
              alt="お墓・霊園探しならライフドット"
              priority
            />
          </a>
        </Link>
      )
    }
    menu={top && <GlobalNavi />}
  />
)

const MemorizedHeader = memo(BeforeMemorizedHeader)
