export const getReviewImagePath = ({
  age,
  gender
}: {
  age: number
  gender: string
}): string => {
  if (gender === 'male') {
    if (age < 30)
      return 'https://s3.lifedot.jp/uploads/ending/path/80041/review_youngman.png'
    if (age < 60)
      return 'https://s3.lifedot.jp/uploads/ending/path/80046/review_middleman.png'
    return 'https://s3.lifedot.jp/uploads/ending/path/80043/review_oldman.png'
  } else {
    if (age < 30)
      return 'https://s3.lifedot.jp/uploads/ending/path/80045/review_youngwoman.png'
    if (age < 60)
      return 'https://s3.lifedot.jp/uploads/ending/path/80044/review_middlewoman.png'
    return 'https://s3.lifedot.jp/uploads/ending/path/80042/review_oldwoman.png'
  }
}
