import { FC, useEffect } from 'react'
import { AppProps } from 'next/app'
import { RelayEnvironmentProvider } from 'relay-hooks/lib/RelayEnvironmentProvider'
import { createEnvironment } from '@lifedot/relay'
import { GlobalStyle } from '@lifedot/atoms/GlobalStyle'
import { DefaultSeo } from '@lifedot/components/Seo'
import { BaseLayout } from '@/layouts/BaseLayout'
import { useEventTracking, usePageView } from '@lifedot/tracking'
import { NextPageContext } from 'next'
import { useRouter } from 'next/router'
import 'polyfill-object.fromentries'
import { QueryPayload } from 'react-relay-network-modern'

// SSRで生成されたmaterialUI用のCSSを削除する https://github.com/mui-org/material-ui/blob/master/examples/nextjs/pages/_app.js
const removeJssStyles = () => {
  const jssStyles = document.querySelector('#jss-server-side')
  if (jssStyles) {
    jssStyles.parentElement?.removeChild(jssStyles)
  }
}

type PageProps = {
  relayData: null | undefined | [[string, QueryPayload]]
}

const App: FC<AppProps<PageProps> & { err: NextPageContext['err'] }> = ({
  Component,
  pageProps,
  err
}) => {
  useEffect(() => {
    removeJssStyles()
  }, [])
  const router = useRouter()
  useEventTracking({
    category: router.pathname === '/' ? 'TOP' : 'POST'
  })

  usePageView()

  return (
    <>
      <DefaultSeo />
      <GlobalStyle />
      <RelayEnvironmentProvider
        environment={createEnvironment(pageProps.relayData)}
      >
        <BaseLayout top={router.pathname === '/'}>
          <Component {...pageProps} err={err} />
        </BaseLayout>
      </RelayEnvironmentProvider>
    </>
  )
}

export default App
