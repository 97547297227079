export const TransitionTimingFunction = {
  easeInOut: 'cubic-bezier(0.5, 0, 0.6, 1.2)',
  easeOut: 'cubic-bezier(0, 0, 0.4, 1)'
} as const

export type TransitionTimingFunctionKeys = keyof typeof TransitionTimingFunction

export const transition = (
  ease: TransitionTimingFunctionKeys,
  time?: number
): string => {
  const newTime = time ?? (ease === 'easeInOut' ? 0.25 : 0.15)
  return `all ${newTime}s ${TransitionTimingFunction[ease]}`
}
