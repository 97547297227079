import { dayjs } from '@lifedot/utils'
import { useEffect, useState } from 'react'

export const useIsPeriod = (startDate: string, endDate: string) => {
  const [isPeriod, setIsPeriod] = useState(false)
  useEffect(() => {
    const today = dayjs()
    if (today.isBetween(startDate, endDate, 'day', '[]')) setIsPeriod(true)
  }, [])
  return isPeriod
}
